import React from 'react';
//utils
import { formatKey } from '../../../../../../../utils';


const TenantDetails = ({ extendedInfoArr }) => {
    return (
        Object.keys(extendedInfoArr).map((key) => (
            key === "labels" ? "" :
            <div className="mb-2">
                <h5 className="font-semibold">{formatKey(key)}</h5>
                <p className="text-gray-500">{extendedInfoArr[key] || 'N/A'}</p>
            </div>
        ))
    );
};


export default TenantDetails;
