import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'cred',
    initialState: {
        cred: null,
    },
    reducers: {
        setCred(state, action) {
            state.cred = action.payload;
        },
        clearCred(state) {
            state.cred = null;
        },
    },
});

export const { setCred, clearCred } = authSlice.actions;

export default authSlice.reducer;
