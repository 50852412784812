import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
//utils
import axios from '../../axios/axios';
//constants
import { para_be } from '../../config';


// Thunk to fetch client PMS data
export const fetchPmsData = createAsyncThunk(
    'table/fetchPmsData',
    async ({}, { rejectWithValue }) => {
        try {
            const tableDataResponse = await axios.get(`${para_be}/settings/get_pms_creds`, { withCredentials: true });
            if (tableDataResponse.data.status >= 400) {
                return rejectWithValue(tableDataResponse.data.msg);
            }
            const activePMS = Object.keys(tableDataResponse.data?.result || {}).length > 0;
            let lastPmsSyncDate = null;
            let pmsMetadata = null;
            try {
                if (activePMS) {
                    pmsMetadata = tableDataResponse.data.result;
                }
            } catch (error) {
                console.log(error);
            }
            return { lastPmsSyncDate, activePMS, pmsMetadata };
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Network error');
        }
    }
);

const initialState = {
    activePMS: false,
    lastPmsSyncDate: null,
    pmsMetadata: null,
    loading: false,
    error: null,
};

const pmsSlice = createSlice({
    name: 'pms',
    initialState,
    reducers: {
        setActivePMS(state, action) {
            state.activePMS = action.payload;
        },
        setLastPmsSyncDate(state, action) {
            state.lastPmsSyncDate = action.payload;
        },
        setPmsMetadata(state, action) {
            state.pmsMetadata = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPmsData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchPmsData.fulfilled, (state, action) => {
                // Update the state with the parameters used in the request
                state.activePMS = action.payload.activePMS;
                state.lastPmsSyncDate = action.payload.lastPmsSyncDate;
                state.pmsMetadata = action.payload.pmsMetadata;
            })
            .addCase(fetchPmsData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload || 'Something went wrong while fetching client PMS data';
            });
    }
});


export const {
    setActivePMS,
    setLastPmsSyncDate,
    setPmsMetadata,
} = pmsSlice.actions;
export default pmsSlice.reducer;