import styles from './table-widget.module.css';
//components
import ScanStatus from './TableWidget/components/ScanStatus/ScanStatus';
// import ScanStatus from './components/ScanStatus';
import ActionsPanelMiMo from './TableTypes/MiMoTable/components/ActionPanel/ActionPanel';
import ActionsPanelReports from './TableTypes/ReportsTable/components/ActionPanel/ActionPanel';
import ActionsPanelScans from './TableTypes/ScansSubmissionTable/components/ActionPanel/ActionPanel';
import ActionsPanelUnits from './TableTypes/UnitsTable/components/ActionPanel';
//constants
import { CategoryFilter } from '../../constants';
//utils
import { dateTimeFormater } from '../../utils/date_utils';


export const CommonHeader = {
    REFERENCE: 'Reference',
    UNITS: 'Units',
    TENANT_NAME: 'Tenant Name',
    SCAN_STATUS: 'Scan Status',
    ACTION: 'Actions',
};

export const colorKeysConfig = {
    'done': 'text-green-500',
    'missing': 'text-red-500',
    'init': 'text-blue-500',
    'failed': styles.textOrange
}

const buildColumnDictionary = ({
    key, value, isSortable=false, isVisibleOnDesktop=true, isVisibleOnMobile=true, 
    headersExtraClasses='', cellsExtraClasses='', customRenderer=null, showTitle=false
}) => (
    {
        key,
        value,
        isSortable,
        isVisibleOnMobile,
        isVisibleOnDesktop,
        headersExtraClasses,
        cellsExtraClasses,
        ...(customRenderer ? {customRenderer: customRenderer} : {}),
        showTitle
    }
)

export const tableConfigurations = {
    [CategoryFilter.MOVE_IN]: [
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Units', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'tenant_name', value: 'Tenant Name', cellsExtraClasses: ' max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary(
            {
                key: 'check_in', value: 'Move-In', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.check_in, 'yyyy/mm/dd')
            }
        ),
        buildColumnDictionary(
            {
                key: 'scanStatus', value: 'Scan Status', isSortable: true,
                customRenderer: (rowData) => (
                    <ScanStatus 
                        scan_date={rowData.scan_date}
                        latest_scan_date={rowData.latest_scan_date}
                        scan_datetime={rowData.scan_datetime}
                        baseline_date={rowData.baseline_date}
                    />
                )
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelMiMo
                            pid={rowData.pid}
                            subject={rowData.subject}
                            latest_report_id={rowData.latest_report_id}
                            ext_ref={rowData.ext_ref}
                            changeSetNotifState={setNotifState}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.MOVE_OUT]: [
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Units', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'tenant_name', value: 'Tenant Name', cellsExtraClasses: ' max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary(
            {
                key: 'check_out', value: 'Move-Out', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.check_out, 'yyyy/mm/dd')
            }
        ),
        buildColumnDictionary(
            {
                key: 'scanStatus', value: 'Scan Status', isSortable: true,
                customRenderer: (rowData) => (
                    <ScanStatus 
                        scan_date={rowData.scan_date}
                        latest_scan_date={rowData.latest_scan_date}
                        scan_datetime={rowData.scan_datetime}
                        baseline_date={rowData.baseline_date}
                    />
                )
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelMiMo
                            pid={rowData.pid}
                            subject={rowData.subject}
                            latest_report_id={rowData.latest_report_id}
                            ext_ref={rowData.ext_ref}
                            changeSetNotifState={setNotifState}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.REPORTS]: [
        buildColumnDictionary(
            {
                key: 'ext_ref', value: 'Reference', headersExtraClasses: ' width-in1000 max-w-in200 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in200 min-w-in0 in-lg:max-w-in150 truncate', 
                isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'subject', value: 'Units', cellsExtraClasses: ' in-lg:max-w-in150 truncate', isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'tenant_name', value: 'Tenant Name', headersExtraClasses: ' width-in1000 max-w-in170 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in170 min-w-in0 truncate', 
                isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'inspection_type', value: 'Report Type', headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0', isSortable: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'report_date', value: 'Report Date', isSortable: true, 
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0', cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => dateTimeFormater(rowData.report_date, 'yyyy/mm/dd')
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in120 min-w-in0 text-right',
                headersExtraClasses: ' width-in1000 max-w-in120 min-w-in0',
                customRenderer: (rowData) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelReports
                            pid={rowData.pid}
                            subject={rowData.subject}
                            report_id={rowData.report_id}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.SCANS]: [
        buildColumnDictionary({key: 'ext_ref', value: 'Reference', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'subject', value: 'Units', cellsExtraClasses: ' max-w-in300 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'tenant_name', value: 'Tenant Name', cellsExtraClasses: ' max-w-in150 truncate', isSortable: true, showTitle: true}),
        buildColumnDictionary({key: 'inspection_type', value: 'Inspection Type', isSortable: true}),
        buildColumnDictionary(
            {
                key: 'date_submitted', value: 'Date Submitted', isSortable: true,
                customRenderer: (rowData) => dateTimeFormater(rowData.date_submitted, 'yyyy/mm/dd')
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                customRenderer: (rowData) => (
                    <div className="flex gap-x-4">
                        <ActionsPanelScans
                            pid={rowData.pid}
                            subject={rowData.subject}
                            report_id={rowData.report_id}
                            videoLink={rowData.ts}
                            submission_status={rowData.submission_status}
                        />
                    </div>
                )
            }
        ),
    ],
    [CategoryFilter.UNITS]: [
        buildColumnDictionary(
            {
                key: 'ext_ref', value: 'Reference', headersExtraClasses: ' width-in1000 max-w-in250 min-w-in0', 
                cellsExtraClasses: ' width-in1000 max-w-in250 min-w-in0 in-lg:max-w-in150 truncate', isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'subject', value: 'Units', cellsExtraClasses: ' in-lg:max-w-in150 truncate', isSortable: true, showTitle: true
            }
        ),
        buildColumnDictionary(
            {
                key: 'lastReport', value: 'Last Report', isSortable: true,
                cellsExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in150 min-w-in0',
                customRenderer: (rowData) => (
                    rowData.latest_scan_date ?
                        <ScanStatus 
                            scan_status="No Status"
                            latest_scan_date={rowData.latest_scan_date}
                            scan_datetime={rowData.latest_scan_date}
                            baseline_date={rowData.baseline_date}
                        />
                        :
                        "N/A"
                )
            }
        ),
        buildColumnDictionary(
            {
                key: CommonHeader.ACTION, value: 'Actions',
                cellsExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                headersExtraClasses: ' width-in1000 max-w-in180 min-w-in0',
                customRenderer: (rowData, setNotifState) => (
                    <div className="flex gap-x-4 ml-4">
                        <ActionsPanelUnits
                            pid={rowData.pid}
                            subject={rowData.subject}
                            latest_report_id={rowData.latest_report_id}
                            ext_ref={rowData.ext_ref}
                            scan_id={rowData.scan_id}
                            baseline_date={rowData.baseline_date}
                            changeSetNotifState={setNotifState}
                        />
                    </div>
                )
            }
        ),
    ]
};
