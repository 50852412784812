import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//components
import Notification from "../../../../../components/side_notification/side_notification";
import BaseModal from '../../../../../components/Modal'
import Btn from "../../../../../components/buttons/standard/btn";
//utils
import { postReqOptBuilder } from "../../../../../utils/main_utils";
//constants
import { para_be } from '../../../../../config';


const BulkUploadModal = ({ open, handleClose, performFetch }) => {
    const endUserData = useSelector((state) => state.config.endUserData);

    const [notifState, setNotifState] = useState(false);
    const [isDragOver, setIsDragOver] = useState(false);
    const [uploadedFile, setUploadedFile] = useState("");
    const [showError, setShowError] = useState(null); 
    const [show, setShow] = useState(false); 
    const [loader, setLoader] = useState(false); //show the progressbar
    const [complete, setcomplete] = useState(false); // Indicates whether a process or task is complete
    
    useEffect(()=> {
    }, []);
    
    const handleUpload = () => {
        setShowError(false);
        setShow(true);
        setLoader(true);
        const formData = new FormData();
        formData.append('csv_file', uploadedFile);
        formData.append('ip', endUserData?.ip);
        
        fetch(para_be + '/units/bulk_upload', postReqOptBuilder(formData, true, {}, false))
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setLoader(false);
                setcomplete(true);
                performFetch();
            } else if (response.status === 403) {
                setLoader(false);
                setShow(false);
                setNotifState(response.msg);
                setShowError(response.msg);
            } else {
                console.log(response);
                setNotifState("An error has occurred, please try again later");
                setLoader(false);
                setShow(false);
            }
        })
        .catch(error => {
            console.log(error);
            setNotifState("An error has occurred, please try again later");
            setLoader(false);
            setShow(false);
        });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        const file = e.dataTransfer.files[0];
        setUploadedFile(file);
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
    };
    
    const handleCloseNotif = () => {
        setNotifState(null);
    }


    return (
        <>
            <BaseModal
                open={open}
                onClose={handleClose}
                maxWidth={800}
            >
                <div className="w-full p-6 bg-white">
                    <div className="flex flex-col space-y-4">
                        <h2 className="text-2xl font-bold text-blue-600">Bulk Upload</h2>
                        <p className="text-sm text-gray-600">
                            Upload a .xlsx/.csv file to create or update in bulk a set of units, tenancies, or both.
                        </p>
                        <p className="text-sm text-gray-600">
                            To upload units, it is necessary to have "Unit" and "Reference" columns.
                            <br />
                            To upload tenancies, it is necessary to have "Tenant Full Name", "Move-In", and "Move-Out" columns.
                            <br />
                            Please note that dates should be submitted in the following format: yyyy-mm-dd.
                        </p>
                        <p className="text-sm text-gray-700 my-4">Here are the available columns in the table:</p>
                        
                        <div className="overflow-x-auto mb-5">
                            <table className="w-full table-auto border-collapse">
                                <thead>
                                    <tr className="border-t border-gray-300">
                                        {[
                                            'Unit', 'Reference', 'Tenant Full Name', 'First Name', 
                                            'Last Name', 'Salutation', 'Email', 'Phone', 
                                            'Move-In', 'Move-Out', 'Deposit', 'Rent', 'Currency'
                                        ].map((header) => (
                                            <th 
                                                key={header} 
                                                className="text-xs font-medium px-1.5 py-1 text-center border border-gray-300"
                                            >
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {[1, 2].map((_, index) => (
                                        <tr key={index} className="border-b last:border-none">
                                            {Array(13).fill('...').map((content, idx) => (
                                                <td 
                                                    key={idx} 
                                                    className="text-xs px-1.5 py-1 border-r last:border-r-0 border-gray-300 text-center"
                                                >
                                                    {content}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div 
                            className={`w-full border-2 border-dashed border-gray-300 p-4 flex items-center justify-center text-gray-600 text-lg ${isDragOver ? 'bg-gray-100' : ''}`}
                            onDrop={handleDrop}
                        >
                            {uploadedFile ? (
                                <div className="text-base font-medium">
                                    {uploadedFile.name}
                                </div>
                            ) : (
                                <label className="flex flex-col items-center justify-center cursor-pointer">
                                    <p>Drag & drop a .xlsx/.csv file here or click to select a file</p>
                                    <input 
                                        type="file" 
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
                                        onChange={handleFileChange} 
                                        className="hidden"
                                    />
                                </label>
                            )}
                        </div>
                        
                        {show && (
                            <>
                                {loader && (
                                    <div className="flex items-center space-x-2">
                                        <span>Processing</span>
                                        <div className="animate-spin h-5 w-5 border-t-2 border-b-2 border-gray-900 rounded-full"></div>
                                    </div>
                                )}
                                {complete && (
                                    <h3 className="text-xl font-semibold text-green-600">Upload Complete</h3>
                                )}
                            </>
                        )}

                        {showError &&
                            <div className="text-red-600 text-sm items-center px-2">
                                <span>{showError}</span>
                            </div>
                        }
                        
                        <Btn
                            text="Upload"
                            type="primary"
                            onclick={handleUpload}
                        >
                            Upload
                        </Btn>
                    </div>
                </div>
            </BaseModal>

            {notifState &&
                <Notification 
                    closeFunc={handleCloseNotif} 
                    text={notifState} 
                    type="error" />
            }
        </>
    )
}

export default BulkUploadModal;