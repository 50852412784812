import React from 'react'
//components
import CustomTooltip from '../../../../CustomTooltip/CustomTooltip';
//utils
import { dateTimeFormater } from '../../../../../utils/date_utils';
//assets
import AlertIcon from '../../../icons/alertIcon';
//constants
import { colorKeysConfig } from '../../../tableConfig';


const ScanStatus = ({ scan_status, latest_scan_date, scan_datetime, baseline_date }) => {
    return (
        <div className={`flex gap-x-1 items-center ${colorKeysConfig[scan_status === 'Done' ? 'done' : scan_status === 'Initialized' ? 'init' : (scan_status === 'No Status' && scan_datetime) ? 'done' : 'missing']}`}>
            { 
                scan_status === "Done" ?
                    <>
                        <span>Done ({latest_scan_date ? dateTimeFormater(latest_scan_date, 'yyyy/mm/dd') : scan_datetime ? dateTimeFormater(scan_datetime, 'yyyy/mm/dd') : ""})</span>
                    </> :
                scan_status === "Initialized" ?
                    <>
                        <span>Failed attempt ({scan_datetime && dateTimeFormater(scan_datetime, 'yyyy/mm/dd')})</span>
                    </> :
                (scan_status === "No Status" && scan_datetime) ?
                    <>
                        <span>{dateTimeFormater(scan_datetime, 'mm/dd/yyyy')}</span>
                    </>
                :
                    <>
                        <span>Missing</span>
                    </>
            }
            { !baseline_date && 
                <CustomTooltip title={
                    <div className='flexRow'>
                        <AlertIcon className="mt-px" innerFill='#F8803D' />
                        <span className="text-sm ml-2">Missing Baseline</span>
                    </div>
                }>
                    <span>
                        <AlertIcon /> 
                    </span>
                </CustomTooltip>
            }
        </div> 
    )
}

export default ScanStatus;