import { configureStore } from '@reduxjs/toolkit';
import customerReducer from './slices/customerSlice';
import subscriptionReducer from './slices/subscriptionsSlice';
import billingReducer from './slices/billingSlice';
import authReducer from './slices/authSlice';
import configReducer from './slices/configSlice';
import notificationsReducer from './slices/notificationSlice';
import baselineScanReducer from './slices/baselineScanSlice';
import dashboardReducer from './slices/dashboardSlice';
import tableReducer from './slices/searchSlice';
import scanReducer from './slices/scanSlice';
import unitsReducer from './slices/unitSlice';
import pmsReducer from './slices/pmsSlice';
import globalSearchReducer from './slices/globalSearchSlice';
import clientLimitsReducer from './slices/clientLimitsSlice';
import activityReducer from './slices/activitySlice';

export const store = configureStore({
    reducer: {
        dashboard: dashboardReducer,
        baselineScan: baselineScanReducer,
        notifications: notificationsReducer,
        customer: customerReducer,
        subscription: subscriptionReducer,
        billing: billingReducer,
        auth: authReducer,
        config: configReducer,
        table: tableReducer,
        units: unitsReducer,
        pms: pmsReducer,
        scans: scanReducer,
        globalSearch: globalSearchReducer,
        clientLimits: clientLimitsReducer,
        activity: activityReducer,
    },
});

export default store;
