import React from "react";
import VideoCamera, {CAM_STATE_PAUSE, CAM_STATE_PLAY, MAX_WIDTH, MAX_HEIGHT, MIME_TYPE, QUALITY} from "./videoCamera";
import computeCosineSimilarity from "compute-cosine-similarity"

//style
import "./videoCamera.css";
import "./interactiveVideoCamera.css";
import compassIC from "../../../assets/icons/Compass.svg"
import { randomInt } from "../../../utils/main_utils";
//components
//containers
//assets

const mobilenet = require('@tensorflow-models/mobilenet');

const positiveMsgs = [
    "Well Done!",
    "You are doing a great job.",
    "You're a rock star.",
    "Keep it going!",
    "You Rock!",
    "Wow! You are a master scanner.",
    "Way to go! You are doing an excellent job.",
    "Sheesh! You are on fire.",
    "Incredible work! You're a true champion.",
    "Outstanding performance!",
    "Bravo! Your skills are top-notch",
    "Sensational! You're making amazing progress.",
    "Remarkable! You are a natural.",
    "You're crushing it!",
    "Wow! You're setting a high bar.",
    "Impressive! You've got the magic touch.",
    "Fantastic! Keep up the great work.",
];

export default class InteractiveVideoCameraV2 extends VideoCamera {
    constructor(props) {
        super(props);

        this.debugMode = (new URLSearchParams(window.location.search)).get('debug') === '1';

        this.lang = props.lang;
        this.instructions = props.tenantScanData[0];
        this.uniFrames = props.tenantScanData[1];
        this.ufData = Object.values(props.tenantScanData[2]);
        console.log("Instructions:");
        console.log(this.instructions);
        console.log("UniFrames:");
        console.log(this.uniFrames);
        console.log("ufData:");
        console.log(this.ufData);
        this.uniFramesPreds = {};
        this.finishedInit = false;
        this.startedInit = false;
        this.lastMatch = null;
        this.innerLastMatch = null;
        this.lastMsgPercentage = 0;
        /*
            // instruction => [{avg_d, avg_m, order, time_left, len}, ...]
            instruction => [{type: <str>, items: <list>}, ...]
            uniFrames   => [{frameIndex, imgUrl}, ...]
         */

        this.state = {
            ...this.state,
            showTF: true,
            showInstruction: true,
            showCompass: true,
            instruction: null,
            targetFrame: this.uniFrames[0],
            approvedFF: false,
            model: null,
            zoomFrame: null,
            scanMsg: null,
            initPercent: 0,
        };

        this.instructionIdx = -1;
        this.targetFrameIdx = 0;
        this.preScanFramesCount = 0;
        this.sockIdx = 0;
        this.matchFrame = false;
        this.freePassCounter = 0;
        // this.instructionNextSwitch = 0;
        // this.targetFrameNextSwitch = 0;
        this.detectedTfList = [];
        this.detectOrigTfList = [];

        this.ufgSims = {};
        this.ufgSimsRev = {};

        // Bind from Parent
        this.test_vars = this.test_vars.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.initializeScanSession = this.initializeScanSession.bind(this);
        this.startStream = this.startStream.bind(this);
        this.changeScanState = this.changeScanState.bind(this);
        this.finalizeStream = this.finalizeStream.bind(this);
        this.blobHandler = this.blobHandler.bind(this);
        this.streamFrames = this.streamFrames.bind(this);
        this.setupVideoRecorder = this.setupVideoRecorder.bind(this);
        
        // Bind from this class
        this.intervalStreamer = this.intervalStreamer.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
        this.waitForScanInit = this.waitForScanInit.bind(this);
        this.updateTargetFrame = this.updateTargetFrame.bind(this);
        this.render = this.render.bind(this);
        this.emitToSocket = this.emitToSocket.bind(this);
        this.matchUniqueFrames = this.matchUniqueFrames.bind(this);

        if (this.props.socket) {
            console.log("[InteractiveVideoCamera] Adding socket event listeners");
            this.props.socket.on('found_target_frame', this.updateTargetFrame);
            this.props.socket.on('match_frame', (_sid) => { this.matchFrame = true;  });
            this.props.socket.on('initialized', () => {
                if (this.finishedInit) {
                    clearInterval(this.checkInitInterval);
                    console.log("Initializing scan...");
                    this.setState({camState: CAM_STATE_PLAY}, () => {
                        console.log(navigator.mediaDevices.getSupportedConstraints());
                        this.startStream(this.props.cameraConstraints);
                    });
                }
            });
        }
        if (this.props.sockets) {
            console.log("[InteractiveVideoCamera] Adding socket event listeners");
            for (let s of this.props.sockets) {
                s.on('finished_tscan', () => {
                    console.log("Received finished scan message!");
                    if (this.state.targetFrame !== null && this.state.camState === CAM_STATE_PLAY) {
                        this.changeScanState();
                        this.setState({targetFrame: null});
                        this.setState({instruction: null});
                    }
                });
                s.on('found_target_frame', this.updateTargetFrame);
                s.on('match_frame', (_sid) => { this.matchFrame = true;  });
                s.on('initialized', () => {
                    if (this.finishedInit && !this.startedInit) {
                        this.startedInit = true;
                        clearInterval(this.checkInitInterval);
                        console.log("Initializing scan...");
                        this.matchUniqueFrames();
                        // this.emitToSocket('tenant_socket_listener', this.sessionID);
                        this.setState({camState: CAM_STATE_PLAY}, () => {
                            console.log(navigator.mediaDevices.getSupportedConstraints());
                            this.startStream(this.props.cameraConstraints);
                        });
                    }
                });
            }
        }
        
        mobilenet.load().then((_model) => { 
            this.setState({initPercent: this.state.initPercent+50})
            console.log("Setting model...", _model);
            this.setState({model: _model}, () => {
                this.uniFrames.map((x, i) => {
                    let im = new Image();
                    im.crossOrigin = "anonymous";
                    im.src = x + "_orig_size";
                    im.onload = () => {
                        let c = document.createElement("CANVAS");
                        c.width = im.width;
                        c.height = im.height;
                        c.getContext("2d").drawImage(im, 0, 0);
                        this.uniFramesPreds[i] = this.state.model.infer(c, true);
                        console.log("Finished preloading uni frame", i);
                        console.log("uniFramesPreds:", this.uniFramesPreds);
                        if (i+1 === this.uniFrames.length) {
                            this.finishedInit = true;
                            this.checkInitInterval = setInterval(this.waitForScanInit, 6000);
                        }

                        this.setState({initPercent: this.state.initPercent+(50/this.uniFrames.length)})
                    };
                    return null;
                });
            });
        });
    }

    matchUniqueFrames() {
        console.log("Iterating uniFrames");
        this.uniFrames.map((_, i) => {
            console.log("[uniFrames] At index:", i);
            if (i>0) {
                let target_idx = this.ufgSimsRev.hasOwnProperty(i-1) ? this.ufgSimsRev[i-1] : i-1;
                console.log("Matching indexes:", i, "<>", target_idx);
                let dist = computeCosineSimilarity(this.uniFramesPreds[i].arraySync()[0], this.uniFramesPreds[target_idx].arraySync()[0]);
                console.log("Matching Distance:", dist);
                if (dist >= 0.875) {
                    console.log("[", i, "x", target_idx, "]", "MATCHED!");
                    this.ufgSims[target_idx] = ((this.ufgSims.hasOwnProperty(target_idx) ? this.ufgSims[target_idx] : 0) + 1)
                    this.ufgSimsRev[i] = target_idx;
                }
            }
            if (i+1 === this.uniFrames.length) {
                console.log("Finished initializing");
                console.log("ufgSims:", this.ufgSims);
                console.log("ufgSimsRev:", this.ufgSimsRev);
            }
            return null;
        });
    }

    waitForScanInit() {
        // this.props.socket.emit('is_scan_initialized', this.sessionID);
        this.emitToSocket('is_scan_initialized', this.sessionID);
    }

    emitToSocket(ev, ...args) {
        let localSockIdx = this.sockIdx;
        if (this.sockIdx+1 === this.props.sockets.length) {
            this.sockIdx = 0;
        } else {
            this.sockIdx++;
        }
        this.props.sockets[localSockIdx].emit(ev, ...args);
    }

    updateTargetFrame(_detectedTf, avoidBlocks=false) {
        /*
        Pre-Prepared [GLOBAL]:
        uniFrames, ufData, instructions

        Beginning:
        approvedFF [STATE]

        Ongoing:
        origTfIdx [LOCAL] 
        detectedTf [LOCAL] 
        nextTfIdx [LOCAL] 
        detectOrigTfList [GLOBAL] - MUST
        detectedTfList [GLOBAL] - MUST
        targetFrameIdx [GLOBAL] - for purposes of knowing what should be the next target frame and if the scan is done.
        innerLastMatch [GLOBAL] 
        
        Ongoing (for visuals):
        targetFrame [STATE] 
        instruction [STATE] 
        instructionIdx [GLOBAL] 

        ----
        
        approvedFF       => A boolean that defines whether the first frame was approved
        origTfIdx        => The index of the target frame that was detected
        detectedTf       => The index of the frame from this scan
        detectOrigTfList => A list of the detected target unique frame indexes
        detectedTfList   => A list of the detected scan frame indexes
        targetFrameIdx   => The current index of the target frame in presentation
        nextTfIdx        => The index of the next target frame to be
        uniFrames        => A list of the unique frames and their relative image url ( e.g: [{frameIndex, imgUrl}, ...] )
        targetFrame      => The current frameIndex imageUrl pair from the uniFrames list
        instruction      => The current instruction presented to the client
        innerLastMatch   => The index of the last unique frame match   // A datetime of the last matched frame that was found
        ufData           => Mapping of the target unique frame indexes and the type of uf it is ( e.g: instruction/ufq )
        instructionIdx   => The index of the current instruction we are covering
        instructions     => A list of all the instructions
        */
        // If the camera is already off we shouldn't try to check for update target frame
        if (this.state.camState === CAM_STATE_PAUSE) return false;
        let origTfIdx = parseInt(_detectedTf.split("@")[0]);
        // If the unique frame was already detected, skip this frame
        if (this.detectOrigTfList.includes(origTfIdx)) return false;

        let detectedTf = parseInt(_detectedTf.split("@")[1]);
        // console.log("Detected target frame:", detectedTf);
        // console.log("Previous targetFrameIdx:", this.targetFrameIdx);
        if (detectedTf === -1) {
            // If the detected target frame is -1 and the first frame was already approved, just skip it
            if (this.state.approvedFF || this.detectedTfList.includes(0)) return false;
            console.log("Approving first frame");
            this.setState({approvedFF: true});
        }

        // Checking if the list of uf passed includes the index of the current uf in this function 
        // and if the current uf index in this function equals to the global uf index (if not it means we already passed it)
        if (!this.detectOrigTfList.includes(origTfIdx) && origTfIdx === this.targetFrameIdx) {
            // console.log("DetectedTF is new");
            let nextTfIdx;
            // Updating the global lists of the target frames that were detected
            this.detectOrigTfList.push(origTfIdx);
            this.detectedTfList.push(detectedTf === -1 ? 0 : detectedTf);
            // Get the next target frame index. If can't find a next one it means the scan is done.
            nextTfIdx = -1;
            for (let i=origTfIdx+1; i<this.uniFrames.length; i++) {
                if (!this.detectOrigTfList.includes(i)) {
                    nextTfIdx = i;
                    break;
                }
            }
            // Check if we are at the end of the scan
            if (origTfIdx+1 === this.uniFrames.length || nextTfIdx === -1) {
                console.log("YAY!\nFinished going over all target frames!!");
                console.log("detectOrigTfList:", this.detectOrigTfList);
                console.log("detectedTfList:", this.detectedTfList);
                // Remove instructions from screen
                this.setState({targetFrame: null});
                this.setState({instruction: null});
                // Wait for 1.5s before closing the camera
                setTimeout(() => { this.changeScanState(); }, 1500);
                return true;
            }
            // console.log("[i] Next Target Frame:", nextTfIdx);
            // Check if the current uf index in this function equals to the global uf index 
            // and if it passed enough frame since last match to find a new one
            if (!avoidBlocks && (origTfIdx !== this.targetFrameIdx || (this.innerLastMatch !== null && (detectedTf - this.innerLastMatch) < 12))) {
                // console.log("Aborting update unique frame.");
                // console.log("Before:");
                // console.log("detectOrigTfList:", this.detectOrigTfList);
                // console.log("detectedTfList:", this.detectedTfList);
                // In case of aborting the match, we remove the added detections
                this.detectOrigTfList = this.detectOrigTfList.slice(0, this.detectOrigTfList.length-1);
                this.detectedTfList = this.detectedTfList.slice(0, this.detectedTfList.length-1);
                // console.log("After:");
                // console.log("detectOrigTfList:", this.detectOrigTfList);
                // console.log("detectedTfList:", this.detectedTfList);
                return false;
            } else {
                // Update global variables and states to let know both the app and user we passed another target frame
                this.targetFrameIdx = nextTfIdx;
                this.innerLastMatch = detectedTf === -1 ? 0 : detectedTf;
                console.log("New UniFrame:", this.uniFrames[nextTfIdx]);
                console.log("New TF Type:", this.ufData[nextTfIdx]);
                this.setState({targetFrame: this.uniFrames[nextTfIdx]}, () => {
                    let e = document.querySelector(".target-frame-container > .tf-wrapper");
                    e.classList.add('found-frame');
                    setTimeout(() => {
                        e.classList.remove('found-frame');
                    }, 1000);
                    let progressPercentage = Math.round(nextTfIdx/this.uniFrames.length*100);
                    if (
                        this.state.scanMsg === null &&
                        (this.lastMsgPercentage === 0 && nextTfIdx > 5 ||
                        (
                            (this.lastMsgPercentage + 14) < progressPercentage && 
                            (detectedTf - this.innerLastMatch) > 40
                        ))
                    ) {
                        this.addIcMsg(
                            <>
                                {progressPercentage > 40 && progressPercentage % 10 < 3 ?
                                    <>
                                        {progressPercentage}% Done.
                                        <br/>
                                    </> : ""
                                }
                                {positiveMsgs[randomInt(0, positiveMsgs.length)]}
                            </>
                        );
                        this.lastMsgPercentage = progressPercentage;
                    }
                });
                // Update instructions if necessary
                if ((this.ufData[nextTfIdx] || '') === 'instruction' || detectedTf === -1) {
                    this.instructionIdx++;
                    let localInstructionIdx = this.instructionIdx;
                    if (localInstructionIdx < this.instructions.length) {
                        console.log("New Instruction:", this.instructions[localInstructionIdx]);
                        this.setState({instruction: this.instructions[localInstructionIdx]});
                    }
                }
                return true;
            }
        }
        return false;
    }

    updateTargetFrame_Backup(_detectedTf) {
        if (this.state.camState === CAM_STATE_PAUSE) return false;
        let origTfIdx = parseInt(_detectedTf.split("@")[0]);
        if (this.detectOrigTfList.includes(origTfIdx)) {
            return false;
        } else this.detectOrigTfList.push(origTfIdx);
        
        let detectedTf = parseInt(_detectedTf.split("@")[1]);
        console.log("Detected target frame:", detectedTf);
        console.log("Previous targetFrameIdx:", this.targetFrameIdx);
        if (detectedTf === -1) {
            // If the detected target frame -1 is emitted again after approving first frame, just skip it
            if (this.state.approvedFF || this.detectedTfList.includes(0)) return false;
            console.log("Approving first frame");
            this.setState({approvedFF: true})
        }
        // if (!this.detectedTfList.includes(detectedTf) && origTfIdx === this.uniFrames.indexOf(this.state.targetFrame)) {
        if (!this.detectedTfList.includes(detectedTf) && origTfIdx === this.targetFrameIdx) {
            console.log("DetectedTF is new");
            let nextTfIdx;
            this.detectedTfList.push(detectedTf === -1 ? 0 : detectedTf);
            nextTfIdx = -1;
            for (let i=this.targetFrameIdx+1; i<this.uniFrames.length; i++) {
                if (!this.detectedTfList.includes(i)) {
                    nextTfIdx = i;
                    break;
                }
            }
            if (this.targetFrameIdx+1 === this.uniFrames.length || nextTfIdx === -1) {
                console.log("YAY!\nFinished going over all target frames!!");
                console.log("detectOrigTfList:", this.detectOrigTfList);
                console.log("detectedTfList:", this.detectedTfList);
                this.setState({targetFrame: null});
                this.setState({instruction: null});
                setTimeout(() => { this.changeScanState(); }, 1500);
                // this.changeScanState();
                return true;
            }
            console.log("[i] Next Target Frame:", nextTfIdx);
            // if (this.innerLastMatch !== null && ((new Date()) - this.innerLastMatch) < 700) {
            if (this.innerLastMatch !== null && (detectedTf - this.innerLastMatch) < 10) {
                console.log("Aborting update unique frame.");
                console.log("Before:");
                console.log("detectOrigTfList:", this.detectOrigTfList);
                console.log("detectedTfList:", this.detectedTfList);
                this.detectOrigTfList = this.detectOrigTfList.slice(0, this.detectOrigTfList.length-1);
                this.detectedTfList = this.detectedTfList.slice(0, this.detectedTfList.length-1);
                console.log("After:");
                console.log("detectOrigTfList:", this.detectOrigTfList);
                console.log("detectedTfList:", this.detectedTfList);
                return false;
            }
            if (nextTfIdx !== -1 && origTfIdx === this.uniFrames.indexOf(this.state.targetFrame)) {
                this.targetFrameIdx = nextTfIdx;
                // this.innerLastMatch = new Date();
                this.innerLastMatch = detectedTf === -1 ? 0 : detectedTf;
                console.log("New UniFrame:", this.uniFrames[nextTfIdx]);
                console.log("New TF Type:", this.ufData[nextTfIdx]);
                this.setState({targetFrame: this.uniFrames[nextTfIdx]}, () => {
                    let e = document.querySelector(".target-frame-container > .tf-wrapper");
                    e.classList.add('found-frame');
                    setTimeout(() => {
                        e.classList.remove('found-frame');
                    }, 1000);
                    let progressPercentage = Math.round(nextTfIdx/this.uniFrames.length*100);
                    this.addIcMsg(
                        <>
                            {progressPercentage > 40 && progressPercentage % 10 < 2 ?
                                <>
                                    {progressPercentage}% Done.
                                    <br/>
                                </> : ""
                            }
                            {positiveMsgs[randomInt(0, positiveMsgs.length)]}
                        </>
                    );
                });
                if ((this.ufData[nextTfIdx] || '') === 'instruction' || detectedTf == -1) {
                    this.instructionIdx++;
                    let localInstructionIdx = this.instructionIdx;
                    if (localInstructionIdx < this.instructions.length) {
                        console.log("New Instruction:", this.instructions[localInstructionIdx]);
                        this.setState({instruction: this.instructions[localInstructionIdx]});
                    }
                }
                return true;
            }
        }
        return false;
    }

    addIcMsg(msg, msg_timeout=2500) {
        this.setState({scanMsg: msg}, () => {
            setTimeout(() => {
                this.setState({scanMsg: null});
            }, msg_timeout);
        });
    }

    renderChildren() {
        if (this.state.streamStarted && this.state.targetFrame !== null) {
            return (
                <div className="ic-cover">
                    <IcProgress 
                        targetFrameIdx={this.targetFrameIdx}
                        totalFrames={this.uniFrames.length}
                    />
                    <IcMsgs
                        msgContent={this.state.scanMsg}
                    />
                    <IcTargetFrame
                        targetFrame={this.state.targetFrame}
                        showTF={this.state.showTF}
                        zoomFrame={this.state.zoomFrame}
                        setZoomFrame={(x) => { this.setState({zoomFrame: x}); }}
                    />
                    <IcLabel
                        instruction={this.instructionIdx === -1 ? {type: "Find the starting frame and hold"} : {type: ""}}
                        showInstruction={this.state.showInstruction}
                        showCompass={false}/>
                    {/* {(this.instructionIdx === -1 || this.state.instruction) && 
                        <IcLabel
                            // instruction={this.instructionIdx === -1 ? {type: "Find the starting frame and hold"} : this.state.instruction}
                            // showInstruction={this.instructionIdx === -1 ? true : this.state.showInstruction}
                            instruction={this.instructionIdx === -1 ? {type: "Find the starting frame and hold"} : {type: ""}}
                            showInstruction={this.state.showInstruction}
                            showCompass={false}/>
                    } */}
                    <div className="d-none">
                        {this.uniFrames.map((item) => <img src={item} alt="preloaded-frames"/>)}
                        <canvas ref={this.canvas_OG_Size} className="d-none"></canvas>
                    </div>
                </div>
            )
        } else {
            super.renderChildren()
        }
    }

    intervalStreamer() {
        let localIndex;
        if (this.state.approvedFF) {
            this.frame_index++;
            localIndex = this.frame_index;
            if (localIndex === 0) {
                return;
            }
        } else {
            this.preScanFramesCount++;
            localIndex = parseFloat(`-1.${this.preScanFramesCount}`);
        }
        let uniFrameIdx = this.targetFrameIdx // this.uniFrames.indexOf(this.state.targetFrame);
        // console.log(`[i/F-${localIndex}] Starting intervalTransmitter`);
        if (!this.canvasSizeSet) {
            // console.log(`[i/F-${localIndex}] Setting canvas size`);
            const [newWidth, newHeight] = this.calculateSize(this.scanner.current);
            // const newWidth = this.scanner.current.videoWidth, newHeight = this.scanner.current.videoHeight;
            this.canvas.current.width = newWidth;
            this.canvas.current.height = newHeight;
            if (newWidth === MAX_WIDTH || newHeight === MAX_HEIGHT) this.canvasSizeSet = true;
        }

        if (!this.context || !(this.context instanceof CanvasRenderingContext2D)) {
            console.log("Context has no value or not instance of CanvasRederingContext2D....");
            console.log("Context:", this.context);
            console.log("Canvas:", this.canvas.current);
            this.context = this.canvas.current.getContext('2d');
        }
        // console.log(`[i/F-${localIndex}] Drawing Frame`);
        this.context.drawImage(this.scanner.current, 0, 0, this.canvas.current.width, this.canvas.current.height);
        let foundTF = false;
        if (this.state.approvedFF && localIndex < 0) {
            console.log("[i/F-(-1)] Aborting blobHandler since first frame was already approved!");
        // } else if (uniFrameIdx !== -1 && uniFrameIdx === this.uniFrames.indexOf(this.state.targetFrame)) {
        } else if (uniFrameIdx !== -1 && uniFrameIdx === this.targetFrameIdx && (localIndex < 5 || localIndex % 5 === 0)) {
            // console.log("Running inference on current canvas");
            let framePreds = this.state.model.infer(this.canvas.current, true);
            let dist = computeCosineSimilarity(this.uniFramesPreds[uniFrameIdx].arraySync()[0], framePreds.arraySync()[0]);
            // console.log("Cosine Distance:", dist);
            try {
                document.querySelector(".label-val").innerHTML = `Match: ${Math.min(100, Math.round(dist/0.8*10000)/100)}%`;
            } catch (error) {
                console.log("Error while updating the match rate...\n", error);
            }
            let termA = (dist >= 0.8 || (this.lastMatch && dist >= 0.75 && ((new Date()) - this.lastMatch) >= 3000) || (this.lastMatch && dist >= 0.7 && ((new Date()) - this.lastMatch) >= 6000) || (this.lastMatch && dist >= 0.65 && ((new Date()) - this.lastMatch) >= 15000));
            let termB = (this.freePassCounter < 5 && uniFrameIdx > 1 && ((new Date()) - this.lastMatch) >= 25000);
            if (termA || termB) {
                // if (uniFrameIdx === this.uniFrames.indexOf(this.state.targetFrame)) {
                if (uniFrameIdx === this.targetFrameIdx) {
                    this.lastMatch = new Date();
                    // console.log("updateTargetFrame:", `${uniFrameIdx}@${localIndex}`);
                    let didUpdate = this.updateTargetFrame(`${uniFrameIdx}@${localIndex}`);
                    if (didUpdate) {
                        if (!termA && termB) {
                            this.freePassCounter += 1;
                            this.addIcMsg(
                                <>
                                    Since you covered this area earlier,
                                    <br/>
                                    you may proceed to the next step.
                                </>
                            );
                        }
                        this.emitToSocket("found_target_frame", this.sessionID, (localIndex < 0) ? 0 : localIndex, uniFrameIdx);

                        if (this.ufgSims.hasOwnProperty(uniFrameIdx)) {
                            for (let j=1; j<this.ufgSims[uniFrameIdx].length; j++) {
                                let didUpdate = this.updateTargetFrame(`${uniFrameIdx+j}@${(localIndex < 0 ? 0 : localIndex)+j}`, true);
                                if (didUpdate) this.emitToSocket("found_target_frame", this.sessionID, ((localIndex < 0 ? 0 : localIndex) + j), uniFrameIdx+j);
                            }
                        }
                    }
                    foundTF = true;
                }
            } else if (this.lastMatch === null) {
                this.lastMatch = new Date();
            } else if (this.matchFrame === true && ((new Date()) - this.lastMatch) >= 3000) {
                this.matchFrame = false;
                this.lastMatch = new Date();
                let didUpdate = this.updateTargetFrame(`${uniFrameIdx}@${localIndex}`);
                if (didUpdate) {
                    this.emitToSocket("found_target_frame", this.sessionID, (localIndex < 0) ? 0 : localIndex, uniFrameIdx);

                    if (this.ufgSims.hasOwnProperty(uniFrameIdx)) {
                        for (let j=1; j<this.ufgSims[uniFrameIdx].length; j++) {
                            let didUpdate = this.updateTargetFrame(`${uniFrameIdx+j}@${(localIndex < 0 ? 0 : localIndex)+j}`, true);
                            if (didUpdate) this.emitToSocket("found_target_frame", this.sessionID, ((localIndex < 0 ? 0 : localIndex) + j), uniFrameIdx+j);
                        }
                    }
                }
                foundTF = true;
            } else if (termA && !termB && ((new Date()) - this.lastMatch) >= 10000) {
                this.addIcMsg(
                    <>
                        Are you struggling?
                        <br/>
                        Click on the image on the top right corner, to have a better view of your target.
                    </>, 5000
                );
            }
        }
        if (localIndex >= 0 || foundTF) {
            // console.log(`[i/F-${localIndex}/C-${this.intervalCount}] Converting canvas to blob`);
            this.canvas.current.toBlob((blob) => {
                // if (this.state.approvedFF && localIndex < 0) {
                //     console.log("[i/F-(-1)] Aborting blobHandler since first frame was already approved!");
                // } else 
                this.blobHandler(blob, localIndex);
            }, MIME_TYPE, QUALITY);
            // console.log(`[i/F-${localIndex}] Adding to interval count`);
            this.intervalCount++;
        }
        // console.log(`[i/F-${localIndex}] Clearing context`);
        try {
            this.context.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height);
        } catch (error) {
            console.log("Error while clearing rect...\n", error);
        }
        // console.log(`[i/F-${localIndex}] Finished iteration`);
    }

    render() {
        return super.render();
    }
}

function IcMsgs (props) {
    const {msgContent} = props;

    return (
        <div className={"top-layer-msg" + (msgContent ? "" : " hide-msg")}>
            <div className="tlm-container">
                {msgContent}
            </div>
        </div>
    )
}

function IcProgress (props) {
    const {targetFrameIdx, totalFrames} = props;

    return (
        <div className="scan-progress-container">
            <div
                className="scan-progress-slider"
                style={{ width: `${(targetFrameIdx / totalFrames) * 100}%`}}
            ></div>
        </div>
    )
}

function IcTargetFrame (props) {
    const {targetFrame, showTF, zoomFrame, setZoomFrame} = props;

    // const [targetFrame, setTargetFrame] = useState(null);
    // const [zoomFrame, setZoomFrame] = useState(false);


    return (
        <div className={`target-frame-container${!showTF ? " d-none" : ""}`}>
            <div className={"tf-wrapper" + (zoomFrame ? " zoom-frame" : "")} onClick={() => { setZoomFrame(!zoomFrame); }}>
                <img src={targetFrame + "_orig_size"} alt="look-for-me" />
            </div>
        </div>
    )
}

function IcLabel (props) {
    const {instruction, showInstruction, showCompass} = props;

    // const [instruction, setInstruction] = useState(null);
    const instructionToDeg = () => {
        const t = instruction['type'].toLowerCase();
        if (t.includes('-')) {
            return false;
        } else {
            return t.includes('forward') || t.includes(" up") ? 270 : t.includes("right") ? 0 : t.includes("left") ? 180 : 90
        }
    };

    const instructionsToLabel = () => {
        // if (instruction['type'].includes('-')) {
        //     return instruction['type'].replace('-', ' and ')
        // } else return instruction['type'];
        return instruction['type'].split("-").map((t) => translationHandler(t)).join(" and ")
    };

    const translationHandler = (t) => {
        if (props.lang === "es") {
            t = t.toLowerCase();
            if (t === "find the starting frame and hold") {
                return "Encuentra la primera imagen y espera";
            } else if (t === "spin left") {
                return "Gira a la izquierda"
            } else if (t === "spin right") {
                return "Gira a la derecha"
            } else if (t === "tilt up") {
                return "Inclina el teléfono hacia arriba"
            } else if (t === "tilt down") {
                return "Inclina el teléfono hacia abajo"
            } else if (t === "hold") {
                return "Espera"
            } else {
                return "Sin resultado"
            }
        } else {
            return t;
        }
    }

    const instructionDeg = instructionToDeg();
    return (
        <div className={`instruction-wrapper${!showInstruction || !instruction ? " d-none" : ""}`}>
            <div className="instruction-label">
                {/* <div className="label-val">{"Move with the arrow"}</div> */}
                {/* <div className="label-counter">5</div> */}
                <div className="label-val">{instructionsToLabel(instruction['type'])}</div>
                {showCompass &&
                    <div className={`instruction-compass`}>
                        <div className={`c-dir`} style={!instruction || instructionDeg === false ? {"display": "none"} : {"--ang": `${instructionDeg}deg`}}>
                            <img src={compassIC} alt="directions"/>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
