import React, {useState, useEffect} from "react";
//components
import Notification from "../../side_notification/side_notification";
//utils
//styles
import "../all_reports/all_reports.css";
import "./search_filter.css";
import { SingleRadioInput } from "../../radioInput/radioInput";
import Btn from "../../buttons/standard/btn";

export default function SearchFilter(props) {
    const [allProperties, setAllProperties] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState(props.currentFilters?.["selected_properties"] || []);
    const [selectedPerformers, setSelectedPerformers] = useState(props.currentFilters?.["selected_performers"] || []);
    const [notifState, setNotifState] = useState(null);
    const [showSD_Range, setShowSD_Range] = useState(false);
    const [sdVal, setSdVal] = useState(props.currentFilters?.scanDate?.opt || "any");
    const [stVal, setStVal] = useState(props.currentFilters?.['scan-type']?.opt || "any");
    const [ssVal, setSsVal] = useState(props.currentFilters?.['scan-status']?.opt || "any");
    
    useEffect(() => {
        if (props.activePMS) {
            fetch(props.para_be + '/units/get_all_properties')
            .then(response => response.json())
            .then(response => {
                //DATA 0-report id, 1-status, 2-datetime
                if (response.status === 200) {
                    setAllProperties(response.result);
                } else setNotifState(response.msg);
            })
            .catch(error => {
                setNotifState("An error has occurred while fetching all properties, please try again later");
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const applyChanges = () => {
        let newFilters = props.currentFilters;
        let radioGroups = [
            {'group_name': 'scan-date', 'key': 'scanDate', 'range_prefix': 'sd-range-'},
            {'group_name': 'scan-type', 'key': 'scan-type'},
            {'group_name': 'scan-status', 'key': 'scan-status'},
        ];
        for (let radioGroup of radioGroups) {
            let cfKey = radioGroup['key'];
            let cfRangePrefix = radioGroup?.['range_prefix'];
            let rgValue = document.querySelector("input[name=" + radioGroup['group_name'] + "]:checked")?.value;
            if (props.currentFilters[cfKey].opt !== rgValue || rgValue === "range") {
                let newRgValue = {opt: rgValue};
                if (rgValue === "range") {
                    let rgRangeStart = document.querySelector("input#" + cfRangePrefix + "start").value;
                    let rgRangeEnd = document.querySelector("input#" + cfRangePrefix + "end").value;
                    if (props.currentFilters?.[cfKey]?.value?.[0] !== rgRangeStart) {
                        newRgValue['value'] = [rgRangeStart];
                    } else {
                        newRgValue['value'] = [props.currentFilters?.[cfKey]?.value?.[0]];
                    }
                    if (props.currentFilters?.[cfKey]?.value?.[1] !== rgRangeEnd) {
                        newRgValue['value'].push(rgRangeEnd);
                    } else {
                        newRgValue['value'].push(props.currentFilters?.[cfKey]?.value?.[1]);
                    }
                }
                newFilters[cfKey] = newRgValue;
            }
        }

        newFilters['selected_properties'] = Array.prototype.slice.call(document.querySelectorAll("input[name=selected_properties]:checked")).map( (el) => el.value );
        newFilters['selected_performers'] = Array.prototype.slice.call(document.querySelectorAll("input[name=selected_performers]:checked")).map( (el) => el.value );

        props.applyChanges(newFilters);
        props.closeFunc(true);
    }

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    return (
        <div className="ar-container sf-container">
            <div>
                <div className="h2 blue-headline">Filter</div>
                <div className="sf-body custom-scrollbar-1">
                    <div className="radio-group-container" id="scan-date">
                        <div className="text-1-3">Scan Date</div>
                        {
                            [
                                {'label': "Any", 'value': "any", 'checked': sdVal === "any"},
                                {'label': "Yesterday", 'value': "1d", 'checked': sdVal === "1d"},
                                {'label': "7 Days", 'value': "7d", 'checked': sdVal === "7d"},
                                {'label': "This Month", 'value': "this_month", 'checked': sdVal === "this_month"},
                            ].map( (radioItem) => {
                                return <SingleRadioInput
                                            name={"scan-date"}
                                            label={radioItem.label}
                                            value={radioItem.value}
                                            checked={radioItem.checked}
                                            onclick={() => setSdVal(radioItem.value)}
                                        />
                            })
                        }
                        <SingleRadioInput
                            name={"scan-date"}
                            label={"Set Range"}
                            value={"range"}
                            checked={sdVal === "range"}
                            onclick={() => { setSdVal("range"); setShowSD_Range(document.querySelector("input[name=scan-date]:checked") === "range"); }}
                        >
                            {(sdVal === "range" || showSD_Range) &&
                                <div className="range-input flexRow">
                                    <input id="sd-range-start" type="date" className="text-1" value={props.currentFilters?.scanDate?.value?.[0]} />
                                    <span>-</span>
                                    <input id="sd-range-end" type="date" className="text-1" value={props.currentFilters?.scanDate?.value?.[1]} />
                                </div>
                            }
                        </SingleRadioInput>
                    </div>
                    <div className="radio-group-container" id="scan-type">
                        <div className="text-1-3">Scan Type</div>
                        {
                            [
                                {'label': "Any", 'value': "any", 'checked': stVal === "any"},
                                {'label': "Baseline Scan", 'value': "baseline", 'checked': stVal === "baseline"},
                                {'label': "Checkout Scan", 'value': "checkout", 'checked': stVal === "checkout"}
                            ].map( (radioItem) => {
                                return <SingleRadioInput
                                            name={"scan-type"}
                                            label={radioItem.label}
                                            value={radioItem.value}
                                            checked={radioItem.checked}
                                            onclick={() => setStVal(radioItem.value)}
                                        />
                            })
                        }
                    </div>
                    <div className="radio-group-container" id="scan-status">
                        <div className="text-1-3">Scan Status</div>
                        {
                            [
                                {'label': "Any", 'value': "any", 'checked': ssVal === "any"},
                                {'label': "Ongoing", 'value': "Initialized", 'checked': ssVal === "Initialized"},
                                {'label': "Done", 'value': "Done", 'checked': ssVal === "Done"}
                            ].map( (radioItem) => {
                                return <SingleRadioInput
                                            name={"scan-status"}
                                            label={radioItem.label}
                                            value={radioItem.value}
                                            checked={radioItem.checked}
                                            onclick={() => setSsVal(radioItem.value)}
                                        />
                            })
                        }
                    </div>
                    { props.employees &&
                        <div className="checkbox-group-container">
                            <div className="text-1-3">Scan Performers</div>
                            {
                                (props.employees || []).map( (employeeItem) => {
                                    return {
                                        label: employeeItem.name, 
                                        value: employeeItem.id, 
                                        checked: selectedPerformers.includes(employeeItem.id)
                                    }
                                }).map( (checkboxItem) => {
                                    return <div className="checkbox-container-1 flexRow" onClick={() => {
                                        let newSelectedPerformers = Array.prototype.slice.call(selectedPerformers);
                                        if (selectedPerformers.includes(checkboxItem.value)) {
                                            newSelectedPerformers.splice(newSelectedPerformers.indexOf(checkboxItem.value), 1);
                                        } else newSelectedPerformers.push(checkboxItem.value);
                                        setSelectedPerformers(newSelectedPerformers);
                                    }}>
                                                <input type="checkbox" name="selected_performers" class="mul-selection-checkbox" value={checkboxItem.value} checked={checkboxItem.checked}/>
                                                <div class="text-2">{checkboxItem.label}</div>
                                            </div>
                                })
                            }
                        </div>
                    }
                    { props.activePMS &&
                        <>
                            <div className="checkbox-group-container">
                                <div className="text-1-3">Properties</div>
                                {
                                    (allProperties || []).map( (propertyItem) => {
                                        let propertyItemVal = propertyItem.name.toLowerCase();
                                        return {
                                            label: `${propertyItem.name} (${propertyItem.count})`, 
                                            value: propertyItemVal, 
                                            checked: selectedProperties.includes(propertyItemVal)
                                        }
                                    }).map( (checkboxItem) => {
                                        return <div className="checkbox-container-1 flexRow" onClick={() => {
                                            let newSelectedProperties = Array.prototype.slice.call(selectedProperties);
                                            if (selectedProperties.includes(checkboxItem.value)) {
                                                newSelectedProperties.splice(newSelectedProperties.indexOf(checkboxItem.value), 1);
                                            } else newSelectedProperties.push(checkboxItem.value);
                                            setSelectedProperties(newSelectedProperties);
                                        }}>
                                                    <input type="checkbox" name="selected_properties" class="mul-selection-checkbox" value={checkboxItem.value} checked={checkboxItem.checked}/>
                                                    <div class="text-2">{checkboxItem.label}</div>
                                                </div>
                                    })
                                }
                            </div>
                        </>
                    }
                </div>
                <div className="btns-wrapper flexRow">
                    <Btn 
                        text="Cancel"
                        type="secondary"
                        extraClasses="text-1-3"
                        onclick={() => props.closeFunc()}/>
                    <Btn 
                        text="Apply Filters"
                        type="primary"
                        extraClasses="text-1-3"
                        onclick={() => applyChanges()}/>
                </div>
            </div>

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}