import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

//mui
import { Button } from "@mui/material";
//components
import { SimpleInputDropdown } from "../../components/dropdowns/simple_dropdown/simple_dropdown";
import MainLoader from "../../components/loaders/main_loader/main_loader";
import EmptyState from "../../components/emptyState/empty_stateV2";
//styles
// import "./move_out.css";
//assets
import DoneIcon from '@mui/icons-material/Done';
import NotificationIcon from '@mui/icons-material/Notifications';
import MeetingRoom from '@mui/icons-material/MeetingRoom';
import DoorFront from '@mui/icons-material/DoorFront';
import logoPlaceholder from "../../assets/placeholders/no-image.jpg"
//utils
import { postReqOptBuilder } from "../../utils/main_utils";
import { dateObjFormatter, dateTimeFormater } from "../../utils/date_utils";


export default function TenantLandingPage(props) {
    const clientBeName = props.match.params.cbe_name;
    const pid = props.match.params.pid;

    const [errorPage, setErrorPage] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [companyBanner, setCompanyBanner] = useState(null);
    const [inspectionsDone, setInspectionsDone] = useState(null);
    const history = useHistory();


    useEffect(() => {
        if (loadingPage === false) {
            fetch(props.para_be + "/media/client_media?client_be_name=" + clientBeName)
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    const graphics = JSON.parse(response.result[0]);
                    try {
                        if (!companyLogo) setCompanyLogo((prevState) => { return prevState ? prevState : graphics.logo });
                    } catch (e) {
                        console.log(e);
                    }
                    try {
                        setCompanyBanner(graphics.banner);
                    } catch (e) {
                        console.log(e);
                    }
                }
            });
        }
    }, [loadingPage]);

    useEffect(() => {
        fetch(
            props.para_be + "/scan/authenticateLandingPage", 
            postReqOptBuilder({pid, clientBeName}, true)
        )
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                setLoadingPage(false);
                setInspectionsDone(response.result);
                if (response.media) setCompanyLogo(response.media);
            }
        })
        .catch(error => {
            console.log(error);
            setErrorPage(true);
        });
    }, [])

    const selectInspection = (inspectionType) => {
        fetch(
            props.para_be + "/scan/authenticateTenantScan",
            postReqOptBuilder({pid, clientBeName, inspection_type: inspectionType}, true)
        )
        .then(response => response.json())
        .then(response => {
            if (response.status === 200) {
                window.open(window.location.origin + "/" + response.result.replace("://", "").split("/").slice(1).join("/"));
            }
        })
    };
    

    return (
        <div>
            <Helmet>
                <title>Paraspot - Client Landing</title>
                <meta name="description" content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office." />
                <meta property="og:title" content="Paraspot - Client Landing" />
                <meta property="og:description" content="Join Paraspot and conduct automatic virtual inspections free of human error, from the comfort of your office." />
            </Helmet>
            <section className="page-content overflow-y-auto" style={{height: "100%"}}>
                {errorPage ?
                    <div className="flex justify-center items-center h-48 mb-8 border border-gray-200 rounded">
                        <EmptyState 
                            headerText="Page is not available" 
                            decriptionText="Invalid client or property." 
                        />
                    </div> : 
                    
                    loadingPage ?
                    <MainLoader /> :
                    <>
                        {/* Background */}
                        <div className="banner-pp">
                            <div className="top-bannerX">
                                <img src={companyBanner} alt="banner" />
                            </div>
                        </div>
                        {/* Foreground */}
                        <div className="login-page user_sign_page">
                            <div className="access-formX">
                                <div className="login-form form">
                                    {/* Client Rounded Logo Section */}
                                    {/* <div className="flex w-72 h-44 mx-auto rounded-xl overflow-hidden">
                                        <img className="max-w-72 max-h-44 rounded-xl justify-center mx-auto" src={companyLogo ? companyLogo : logoPlaceholder} alt="logo"/>
                                    </div> */}
                                    <div className="w-36 h-36 max-w-36 max-h-36 mx-auto rounded-full overflow-hidden">
                                        <img className="h-36 rounded-full justify-center mx-auto" src={companyLogo ? companyLogo : logoPlaceholder} alt="logo"/>
                                    </div>
                                    
                                    <div className="py-12">
                                        <h2 className="text-2xl font-bold mb-4 text-center">
                                            {props?.landingType === "venue" ?
                                                "Your Upcoming Event" :
                                                `Your stay with ${clientBeName}`
                                            }
                                        </h2>
                                        <div className="mx-auto mb-6" style={{width: "fit-content"}}>
                                            <span className='block text-gray-500 text-sm mt-1 pt-0 text-center'>
                                                {props?.landingType === "venue" ?
                                                    <>
                                                        We look forward to your upcoming reservation.<br/>
                                                        As part of our rental experience, we ask that you perform an inspection
                                                        upon checking in and checking out of the event space.
                                                    </> :
                                                    <>
                                                        Thank you for renting with {clientBeName}. <br/>
                                                        As part of our rental experience we ask of you to perform an inspection
                                                        upon moving in and moving out of the accommodations.
                                                    </>
                                                }
                                            </span>
                                        </div>
                                        <div className="mx-auto py-2" style={{width: "fit-content"}}>
                                            {inspectionsDone?.checkin ?
                                                <>
                                                    <div className="mx-auto" style={{width: "fit-content"}}>
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<DoneIcon sx={{ width: 24, height: 24 }} />}  
                                                            sx={{
                                                                color: 'white',
                                                                fontSize: '16px',
                                                                textTransform: 'none',
                                                                borderRadius: '8px',
                                                                padding: '8px 32px',
                                                                paddingRight: '44.3px',
                                                            }}
                                                            disabled={true}
                                                        >
                                                            {props?.landingType === "venue" ? "Check-In" : "Move-In"}
                                                        </Button>
                                                    </div>
                                                    <div className="mt-1">
                                                        <span className="text-sm">
                                                            Done at: {dateTimeFormater(inspectionsDone.checkin.datetime, "mm/dd/yyyy HH:MM")}
                                                        </span>
                                                    </div>
                                                </> :
                                                <div className="mx-auto" style={{width: "fit-content"}}>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<MeetingRoom sx={{ width: 24, height: 24 }} />}  
                                                        sx={{
                                                            backgroundColor: '#4F46E5', 
                                                            color: 'white',
                                                            fontSize: '16px',
                                                            textTransform: 'none',
                                                            borderRadius: '8px',
                                                            padding: '8px 32px',
                                                            paddingRight: '44.3px',
                                                            '&:hover': { backgroundColor: '#4338CA' } 
                                                        }}
                                                        onClick={() => selectInspection("checkin")}
                                                    >
                                                        {props?.landingType === "venue" ? "Check-In" : "Move-In"}
                                                    </Button>
                                                </div>
                                            }
                                        </div>

                                        <div className="mx-auto py-2" style={{width: "fit-content"}}>
                                            {inspectionsDone?.checkout ?
                                                <>
                                                    <div className="mx-auto" style={{width: "fit-content"}}>
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<DoneIcon sx={{ width: 24, height: 24 }} />}  
                                                            sx={{
                                                                color: 'white',
                                                                fontSize: '16px',
                                                                textTransform: 'none',
                                                                borderRadius: '8px',
                                                                padding: '8px 32px',
                                                                paddingRight: '44.3px',
                                                            }}
                                                            disabled={true}
                                                        >
                                                            {props?.landingType === "venue" ? "Check-Out" : "Move-Out"}
                                                        </Button>
                                                    </div>
                                                    <div className="mt-1">
                                                        <span className="text-sm">
                                                            Done at: {dateTimeFormater(inspectionsDone.checkout.datetime, "mm/dd/yyyy HH:MM")}
                                                        </span>
                                                    </div>
                                                </> :
                                                <div className="mx-auto" style={{width: "fit-content"}}>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<DoorFront sx={{ width: 24, height: 24 }} />}  
                                                        sx={{
                                                            backgroundColor: '#4F46E5', 
                                                            color: 'white',
                                                            fontSize: '16px',
                                                            textTransform: 'none',
                                                            borderRadius: '8px',
                                                            padding: '8px 32px',
                                                            '&:hover': { backgroundColor: '#4338CA' } 
                                                        }}
                                                        onClick={() => selectInspection("checkout")}
                                                    >
                                                        {props?.landingType === "venue" ? "Check-Out" : "Move-Out"}
                                                    </Button>
                                                </div>
                                            }
                                        </div>

                                        {/* <div className="mt-12">
                                            <label
                                                className="block text-m font-bold mb-2"
                                                htmlFor="portfolioSize"
                                            >
                                                Would you like to be notified?
                                            </label>
                                            <div className="my-4">
                                                <SimpleInputDropdown
                                                    placeholder={"In How Many Hours?"}
                                                    extraClasses={"dd-full-width max-h-64"}
                                                    extraClassesIn={"max-h-28 overflow-y-auto"}
                                                    // selectorClasses={"dd-minw-140"}
                                                    value={null}
                                                    items={{
                                                        h1: { present: "1 Hour" },
                                                        h2: { present: "2 Hours" },
                                                        h3: { present: "3 Hours" },
                                                        h4: { present: "4 Hours" },
                                                        h5: { present: "5 Hours" },
                                                        h6: { present: "6 Hours" },
                                                        h7: { present: "7 Hours" },
                                                        h8: { present: "8 Hours" },
                                                    }}
                                                    closeOnSelection={true}
                                                    onclick={(selectedValue, e) => {
                                                        // setCustomerData(prev => ({...prev, portfolioType: selectedValue}));
                                                    }}
                                                />
                                            </div>
                                            <div className="flex">
                                                <input
                                                    className="shadow appearance-none border rounded-l w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="email-address"
                                                    type="text"
                                                    name="email-address"
                                                    placeholder="Enter your email address"
                                                    // value={customerData.portfolioSize}
                                                    // onChange={handleInputChange}
                                                    required />
                                                <Button
                                                    variant="contained"
                                                    startIcon={<NotificationIcon sx={{ width: 24, height: 24 }} />}  
                                                    sx={{
                                                        backgroundColor: '#262626', 
                                                        color: 'white',
                                                        fontSize: '14px',
                                                        textTransform: 'none',
                                                        borderRadius: '0 8px 8px 0',
                                                        padding: '6px 24px',
                                                        '&:hover': { backgroundColor: '#1A1A1A' } 
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </section>
        </div>
    );
}