import React from "react";
//styles
import "./btn.css";

export default function Btn (props) {
    //Types: primary - blue, secondary - white, landing - purple
    return (
        <button className={"btn-standard btn-" + props.type + (props.btnDisabled ? " btn-disabled" : "") + (props.extraClasses ? ` ${props.extraClasses}` : "")} onClick={(e)=>props.onclick(e)}>
            {props.text}
        </button>
    )
}