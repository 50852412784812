import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
//components
import EmptyState from "../../emptyState/empty_state";
import MainLoader from "../../loaders/main_loader/main_loader";
import Notification from "../../side_notification/side_notification";
//utils
import {dateTimeFormater,isToday} from "../../../utils/date_utils.js";
//styles
import "./all_reports.css";
import { capitalizeString } from "../../../utils/main_utils";

export default function AllReports(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [allReportsData, setAllReportsData] = useState(null);
    const [isEmptyState, seEmptyState] = useState(false);
    const [notifState, setNotifState] = useState(null);
    
    useEffect(() => {
        fetch(props.para_be + '/reports/get_all?cid=1&pid=' + props.pid, {credentials: "include"})
        .then(response => response.json())
        .then(response => {
            //DATA 0-report id, 1-status, 2-datetime
            if (response.status === 200) {
                if (response.result.length > 0) {
                    setAllReportsData(response.result);
                } else seEmptyState(true);
            } else setNotifState(response.msg);
            setIsLoading(false);
        })
        .catch(error => {
            setNotifState("An error has occurred, please try again later");
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCloseNotif = () => {
        setNotifState(null);
    }

    return (
        <div className="ar-container">
            {isLoading ? <MainLoader/> :
                <div>
                    <div className="h2 blue-headline">All reports</div>
                    <div className="text-1 ar-address">{props.subject}</div>
                    <div className="ar-body custom-scrollbar-1">
                        {isEmptyState ? <EmptyState text="No reports available." size="md"/> :
                            allReportsData.map((item) => {
                                const rowStatus = (item[1] !== "1" ? null : (item[1] === "1" ? "Pass" : "Fail"));
                                const format = isToday(item[2]) ? "HH:MM" : "dd/mm/yyyy";
                                const dateTimeToPrint = dateTimeFormater(item[2], format);
                                const linkParams = {
                                    pathname: "report/" + item[0],
                                    state: {"pid": props.pid,
                                            "reportId": item[0],
                                            "sortColumn": props.sortColumn,
                                            "sortDirection": props.sortDirection,
                                            "search": props.search,
                                            "filters": props.searchFilters,
                                            "page": props.page,
                                            "subject": props.subject,
                                            "isAllReports": true}
                                };
                                return (
                                    <div>
                                        <Link className="ar-link" to={linkParams}>
                                            <div className="ar-item-header">
                                                <div className="text-1-2 ar-item-date">
                                                    {dateTimeToPrint}
                                                    {rowStatus == null ? "" : <span className={"ls-status para-label para-l-" + rowStatus.toLocaleLowerCase()}>{rowStatus}</span>}
                                                </div>
                                            </div>
                                            <div className="ar-item-breakdown">
                                                {
                                                    ["inventory", "structural_defects", "inventory_defects", "organization"].map((arKey) => {
                                                        return (
                                                            <div className="ar-item text-3">
                                                                <span>{capitalizeString(arKey.split("_").join(" "))}</span>
                                                                <span>{item[3] && item[3]?.hasOwnProperty(arKey) ? item[3][arKey] : "?"}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            }

            {notifState ?
                <Notification
                    closeFunc={handleCloseNotif}
                    text={notifState}
                    type="error"/> : ""
            }
        </div>
    )
}