import React, { useState, useEffect } from "react";

// components
import SimpleInput from "../../../../../components/inputs/simple_input/input";
import { SimpleInputDropdown } from "../../../../../components/dropdowns/simple_dropdown/simple_dropdown";
import { RadioInput } from "../../../../../components/radioInput/radioInput";
import Btn from "../../../../../components/buttons/standard/btn";
import ReactQuill from 'react-quill';
// assets
import copyIcon from "../../../../../assets/icons/copy.svg";
import deleteIcon from "../../../../../assets/icons/DeleteOutlined.svg";
import collapsedIcon from "../../../../../assets/icons/dd-arrow.svg";
import expandIcon from "../../../../../assets/icons/up-arrow.svg";
import paraIcon from "../../../../../assets/icons/logo.svg";
// styles
import "./index.css";
import "./emailStyle.css";
import 'react-quill/dist/quill.snow.css';
// constants
const fields = [["text", "subject", "Subject"]];

const initialSubject = "Instructions for move-in/move-out day";
const initialContent = `<h2>Dear %Name%,</h2>
<p>I hope this email finds you well. We would like to inform you about the upcoming move-in/move-out process for your property at %unit_address%<br/>
Our goal is to ensure a smooth transition for you, and we appreciate your cooperation in this matter.<br/>
<br/>
<br/>
%scan_link%<br/>
<br/>
<br/>
%paraspot_support%<br/>
Thank you for choosing paraspot for your property needs.<br/>
We look forward to assisting you with your move-in or move-out process and ensuring your satisfaction.<br/>
<br/>
<br/>
<br/>
Warm regards,
<br/>
%name%</p>
`;
const initialEmailData = () => {
    return {
        sendType: "",
        sendWhen: {amount: "", type: ""},
        subject: initialSubject,
        body: initialContent,
        isCollapsed: true, // Initially, email box is collapsed,
        variable: "",
    };
};


export default function EmailConfiguration(props) {
    const {emailData, setEmailData, setNotifState} = props;

    const [emailToggle, setEmailToggle] = useState(!emailData || emailData.length === 0 ? "Disable" : "Enable");
    
    
    const handleCopy = (index) => {
        navigator.clipboard.writeText(`%${emailData[index].variable}%`);
        setNotifState({text: "Variable copied to clipboard", type: "success"});
    };

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedBoxes = [...emailData];
        updatedBoxes[index][name] = value;
        setEmailData(updatedBoxes);
    };

    const handleEditorChange = (content, index) => {
        const updatedBoxes = [...emailData];
        updatedBoxes[index]['body'] = content;
        setEmailData(updatedBoxes);
    };
    
    const toggleCollapse = (index) => {
        const updatedBoxes = [...emailData];
        updatedBoxes[index].isCollapsed = !updatedBoxes[index].isCollapsed;
        setEmailData(updatedBoxes);
    };
    
    const addEmailBox = () => {
        setEmailData([...emailData, initialEmailData()]);
    };
    
    const removeEmailBox = (index) => {
        const updatedBoxes = [...emailData];
        updatedBoxes.splice(index, 1);
        setEmailData(updatedBoxes);
    };

    const prepEmailPreview = (msg) => {
        return msg.replace("%scan_link%", '<a class="primary-btn" href="#">Move-In/Out Scan</a>')
                    .replaceAll("%name%", "Isaac").replaceAll("%unit_address%", "1234 Main St, New York, NY 10001")
                    .replaceAll("%number%", "+1 123 456 7890").replaceAll("%inspection_type%", "Move-In")
                    .replaceAll("%move_in%", "2021-08-01").replaceAll("%move_out%", "2021-08-31")
                    .replaceAll("%deposit%", "$1000").replaceAll("%rent%", "$1000")
                    .replaceAll("%paraspot_support%", `If you have any questions don't hesitate to contact our team on <a href="tel:+493062931420">+49 (0)3062931420</a>.`)
                    .replaceAll("<p>", "<p style=\"margin: 0;\">").replaceAll("<h2>", "<h3>").replaceAll("</h2>", "</h3>");
    };

    const whenTypeItems = {
        "days_before": { present: "Day/s Before" },
        "weeks_before": { present: "Week/s Before" },
        "months_before": { present: "Month/s Before" },
        "days_after": { present: "Day/s After" },
        "weeks_after": { present: "Week/s After" },
        "months_after": { present: "Month/s After" },
    };
    
    
    return (
        <section className="auto-email-section">
            <div>
                <div>
                    <label htmlFor="emailToggle" className="h3">Setup Automatic Emailing</label>
                </div>
                <div>
                    <RadioInput
                        id="emailToggle"
                        values={["Enable", "Disable"]}
                        currentValue={emailToggle}
                        onChange={setEmailToggle} />
                </div>
            </div>
            
            {emailToggle === 'Enable' && (
                <>
                    <div className="addmore">
                        <label className="h3">Automatic Emails</label>
                        <Btn 
                            onclick={addEmailBox} 
                            text="Add More +" 
                            type="primary" />
                    </div>
                    {/* {(!emailData || emailData.length === 0 ? [initialEmailData()] : emailData).map((emailBox, index) => */}
                    {(emailData).map((emailBox, index) =>
                        <section key={index} className="email-container">
                            <div className="email-box">
                                <div className="h3">Email #{index + 1}</div>
                                <div className="email-action-btns">
                                    <button onClick={() => toggleCollapse(index)}>
                                        {emailBox.isCollapsed ?
                                            <img src={collapsedIcon} width={15} />
                                        :
                                            <img src={expandIcon} width={15} />
                                        }
                                    </button>
                                    <button onClick={() => removeEmailBox(index)}>
                                        <img src={deleteIcon} />
                                    </button>
                                </div>
                            </div>
                            {!emailBox.isCollapsed &&
                                <>
                                    <div>
                                        <hr />
                                    </div>
                                    <div className="auto-email-config">
                                        <div>
                                            <label className="simple-input-label text-1-3">
                                                Send Email upon
                                            </label>
                                            <RadioInput
                                                values={["Check In", "Check Out"]}
                                                currentValue={emailBox.sendType}
                                                onChange={(selectedValue) => {
                                                    const updatedBoxes = [...emailData];
                                                    updatedBoxes[index].sendType = selectedValue;
                                                    setEmailData(updatedBoxes);
                                                }} />
                                        </div>
                                        <div className="auto-email-when">
                                            <label className="simple-input-label text-1-3">
                                                When?
                                            </label>
                                            <div className="flexRow">
                                                <SimpleInput
                                                    id={"e" + index + "-when-amount"}
                                                    name={"e" + index + "-when-amount"}
                                                    placeholder={"Amount"}
                                                    type={'number'}
                                                    value={emailBox.sendWhen.amount}
                                                    on_change={(e) => {
                                                        const updatedBoxes = [...emailData];
                                                        updatedBoxes[index].sendWhen.amount = e.target.value;
                                                        setEmailData(updatedBoxes);
                                                    }} />
                                                <div className="center-wrapper">
                                                    <SimpleInputDropdown
                                                        value={emailBox.sendWhen.type ? whenTypeItems[emailBox.sendWhen.type].present : undefined}
                                                        items={whenTypeItems}
                                                        closeOnSelection={true}
                                                        onclick={(selectedValue) => {
                                                            const updatedBoxes = [...emailData];
                                                            updatedBoxes[index].sendWhen.type = selectedValue;
                                                            setEmailData(updatedBoxes);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="var-dropdown">
                                            <SimpleInputDropdown
                                                label={"Email Variables"}
                                                selectorClasses={"dd-minw-140"}
                                                value={emailBox.variable}
                                                items={{
                                                    name: { present: "Name" },
                                                    deposit: { present: "Deposit" },
                                                    rent: { present: "Rent" },
                                                    unit_address: { present: "Unit Subject" },
                                                    inspection_type: { present: "Inspection Type" },
                                                    move_in: { present: "Move In" },
                                                    move_out: { present: "Move Out" },
                                                    paraspot_support: { present: "Paraspot Support" },
                                                    scan_link: { present: "Scan Link" },
                                                }}
                                                closeOnSelection={true}
                                                onclick={(selectedValue, e) => {
                                                    const updatedBoxes = [...emailData];
                                                    updatedBoxes[index].variable = selectedValue;
                                                    setEmailData(updatedBoxes);
                                                }}
                                            />
                                            <div className="copy-icon-container">
                                                <img
                                                    className="copy-icon"
                                                    width="15"
                                                    height="15"
                                                    src={copyIcon}
                                                    alt="copy-text"
                                                    onClick={() => handleCopy(index)}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </div>
                                        </div>
                                        <div className="mbt-20">
                                            {fields.map(([type, id, text]) =>
                                                <SimpleInput
                                                    key={id}
                                                    id={id}
                                                    name={id}
                                                    label={text}
                                                    placeholder={text}
                                                    type={type}
                                                    value={emailBox[id]}
                                                    on_change={(e) => handleChange(e, index)} />
                                            )}
                                        </div>
                                        <div className="simple-input-label wrapper-text">
                                            <label className="simple-input-label text-1-3">
                                                Email Body
                                            </label>
                                            {/* <textarea
                                                rows={15}
                                                className="email-body"
                                                defaultValue={emailBox.body}
                                                name="body"
                                                onChange={(e) => handleChange(e, index)} /> */}

                                            <ReactQuill 
                                                className="email-body"
                                                theme={"snow"}
                                                onChange={(content) => handleEditorChange(content, index)}
                                                value={emailBox.body}
                                                modules={{
                                                    toolbar: [
                                                        [{ 'header': [1, 2, false]}],
                                                        [],
                                                        ['bold', 'italic', 'underline'],
                                                        [],
                                                        [],
                                                        []
                                                    ],
                                                    clipboard: {
                                                        // toggle to add extra line breaks when pasting HTML:
                                                        matchVisual: false,
                                                    }
                                                }}
                                                formats={['header', 'bold', 'italic', 'underline']}
                                                bounds={'.app'} />

                                            <div className="preview-email-title h3">Email Preview</div>
                                            <frame className="email-preview-frame">
                                                <div>
                                                    <section class="email-content">
                                                        <div class="email-header">
                                                            <img src={paraIcon} />
                                                        </div>
                                                        <div class="main-content" dangerouslySetInnerHTML={{__html: prepEmailPreview(emailBox['body'])}}>
                                                        </div>
                                                        <div class="email-footer">
                                                            <p>Need help? Ask at <a href="mailto:info@paraspot.co">info@paraspot.co</a></p>
                                                            <div class="contact-info">
                                                                <span class="cname">Paraspot GmbH</span>
                                                                <div class="rd-pt-sn">
                                                                    <a href="https://instagram.com/paraspot_re" target="_blank"><img src="https://www.paraspot.ai/icons/email_icons/instagram.jpeg" width="16.2px" height="16.2px" alt="Instagram"/></a>
                                                                    <a href="https://facebook.com/paraspotRE" target="_blank"><img src="https://www.paraspot.ai/icons/email_icons/facebook.jpeg" width="16.2px" height="16.2px" alt="Facebook"/></a>
                                                                    <a href="https://linkedin.com/company/paraspot-gmbh" target="_blank"><img src="https://www.paraspot.ai/icons/email_icons/linkedin.jpeg" width="16.2px" height="16.2px" alt="LinkedIn"/></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </frame>
                                        </div>
                                    </div>
                                </>
                            }
                        </section>
                    )}
                </>
            )}
        </section>
    );
}