import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCookie } from '../utils/cookie_monster';
import { postReqOptBuilder } from '../utils/main_utils';
import { setCred, clearCred } from '../store/slices/authSlice';
import { para_be } from '../config';
import axios from '../axios/axios';

const useAuthenticate = () => {
    const cred = useSelector(state => state.auth.cred);
    const dispatch = useDispatch();
    const location = useLocation();
    const token = useRef(null);
    const [settingClient, setSettingClient] = useState(null);
    const [settingUser, setSettingUser] = useState(null);

    const authentication = async () => {
        if (cred) {
            return;
        }

        if (token.current) {
            console.log('token has value');
        } else if (location.state && location.state.from === '/login') {
            token.current = location.state.auth;
        } else {
            const cookie_response = await getCookie(para_be, 'AuthToken');
            console.log(cookie_response);

            if (cookie_response && cookie_response['status'] === 200) {
                token.current = cookie_response['result'];
            } else if (localStorage.getItem('AuthToken')) {
                token.current = localStorage.getItem('AuthToken');
            } else {
                if (cred !== null) dispatch(clearCred());
                return;
            }
        }
        if (token.current) await authenticateToken(token.current);
    };

    const authenticateToken = async (token) => {
        if (token) {
            const { result } = await remoteAuthentication(token);
            const { uid, cid, userType } = result;
            if (uid) {
                dispatch(setCred({ uid, cid, userType }));
            } else {
                dispatch(clearCred());
            }
        }
    };

    const remoteAuthentication = async token => {
        return fetch(para_be + '/auth/authenticate', postReqOptBuilder({ auth_key: token })).then(response => response.json());
    };

    const handleSignOut = () => {
        dispatch(clearCred());
        localStorage.removeItem('AuthToken');
        token.current = null;
    };

    useEffect(() => {
        const fetchData = async () => {
    
            try {
                const response = await axios.get(`${para_be}/settings/get-data`, {
                        withCredentials: true
                });
                if (response.status === 200) {
                      const [name,lastName,{email}] = response.data.result.user_data;
                      const clientName = response.data.result.company_data[0];
                      const user = {fullName:`${name} ${lastName}`, email, firstName: name, lastName};
                      const client = {name: clientName};
                      setSettingUser(user);
                      setSettingClient(client);
                } 
             }catch(error) {
                console.error('Failed to fetch settings: ' + error.message);
             } 
          };
    
        fetchData();
    }, [])

    return { handleSignOut, token, authentication, settingUser };
};

export default useAuthenticate;
